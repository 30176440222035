import { Popover, Tab } from '@headlessui/react'
import { Link, RouterProvider, createBrowserRouter, useParams } from 'react-router-dom';
import useSWR from 'swr';
import { client } from '.';
import { Fragment } from 'react';
import { CheckIcon, QuestionMarkCircleIcon, StarIcon } from '@heroicons/react/20/solid'
import { RadioGroup } from '@headlessui/react'
import { ShieldCheckIcon } from '@heroicons/react/24/outline'
import classNames from './utils/classNames';
import { HeartIcon, MinusIcon, PlusIcon } from '@heroicons/react/24/outline'

function ProductPage() {
  const { id } = useParams();
  const { data, isLoading } = useSWR(
    `product/${id}`,
    () => {
      return client.collection('parts').getOne(id);
    },
  );

  if (!data) {
    return "loading";
  }

  return (
    <div className="bg-white">
      <div className="mx-auto max-w-2xl px-4 py-16 sm:px-6 sm:py-24 lg:max-w-7xl lg:px-8">
        <div className="lg:grid lg:grid-cols-2 lg:items-start lg:gap-x-8">
          {/* Image gallery */}
          <Tab.Group as="div" className="flex flex-col-reverse">
            {/* Image selector */}
            <div className="mx-auto mt-6 hidden w-full max-w-2xl sm:block lg:max-w-none">
              <Tab.List className="grid grid-cols-4 gap-6">
                {data.images.map((image) => (
                  <Tab
                    key={image}
                    className="relative flex h-24 cursor-pointer items-center justify-center rounded-md bg-white text-sm font-medium uppercase text-gray-900 hover:bg-gray-50 focus:outline-none focus:ring focus:ring-opacity-50 focus:ring-offset-4"
                  >
                    {({ selected }) => (
                      <>
                        <span className="absolute inset-0 overflow-hidden rounded-md">
                          <img src={client.files.getUrl(data, image)} alt="" className="h-full w-full object-cover object-center" />
                        </span>
                        <span
                          className={classNames(
                            selected ? 'ring-indigo-500' : 'ring-transparent',
                            'pointer-events-none absolute inset-0 rounded-md ring-2 ring-offset-2'
                          )}
                          aria-hidden="true"
                        />
                      </>
                    )}
                  </Tab>
                ))}
              </Tab.List>
            </div>

            <Tab.Panels className="aspect-h-1 aspect-w-1 w-full">
              {data.images.map((image) => (
                <Tab.Panel key={image}>
                  <img
                    src={client.files.getUrl(data, image)}
                    alt={`${data.name} image ${image}`}
                    className="h-full w-full object-cover object-center sm:rounded-lg"
                  />
                </Tab.Panel>
              ))}
            </Tab.Panels>
          </Tab.Group>

          {/* Product info */}
          <div className="mt-10 px-4 sm:mt-16 sm:px-0 lg:mt-0">
            <h1 className="text-3xl font-bold tracking-tight text-gray-900">{data.name}</h1>

            <div className="mt-3 flex justify-between items-center">
              <p className="text-3xl tracking-tight text-gray-900">
                ${data.price}
              </p>
              {data.stock === 0 ? (
                <p className="text-2xl tracking-tight text-red-600">
                  No items in stock
                </p>
              ) : (
                <p className="text-2xl tracking-tight text-green-600">
                  {data.stock} {data.stock === 1 ? "item" : "items"} in stock
                </p>
              )}
            </div>

            <div className="mt-6">
              <div
                className="space-y-6 text-base text-gray-700"
                dangerouslySetInnerHTML={{ __html: data.description }}
              />
            </div>

            <form className="mt-6">
              <div className="mt-10 flex">
                <button
                  type="submit"
                  className="flex max-w-xs flex-1 items-center justify-center rounded-md border border-transparent bg-indigo-600 px-8 py-3 text-base font-medium text-white hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 focus:ring-offset-gray-50 sm:w-full"
                >
                  Add to bag
                </button>

                <button
                  type="button"
                  className="ml-4 flex items-center justify-center rounded-md px-3 py-3 text-gray-400 hover:bg-gray-100 hover:text-gray-500"
                >
                  <HeartIcon className="h-6 w-6 flex-shrink-0" aria-hidden="true" />
                  <span className="sr-only">Add to favorites</span>
                </button>
              </div>
            </form>

            <section aria-labelledby="details-heading" className="mt-12">
              <h2 id="details-heading" className="sr-only">
                Additional details
              </h2>
            </section>
          </div>
        </div>
      </div>
    </div>

  );
}

function Category({ categoryId }) {
  const {
    data,
    isLoading,
  } = useSWR(
    `categories/${categoryId}/parts`,
    (key) => {
      return client.collection('parts').getList(undefined, undefined, {
        filter: `category = "${categoryId}"`,
      });
    },
  );

  return (
    data?.items.map((product) => (
      <Link key={product.id} className="group relative" to={`/part/${product.id}`}>
        <div className="h-56 w-full overflow-hidden rounded-md bg-gray-200 group-hover:opacity-75 lg:h-72 xl:h-80">
          <img
            src={client.files.getUrl(product, product.images[0])}
            alt={`${product.name} image`}
            className="h-full w-full object-cover object-center"
          />
        </div>
        <h3 className="mt-4 text-sm text-gray-700">
          <a href={product.href}>
            <span className="absolute inset-0" />
            {product.name}
          </a>
        </h3>
        <p className="mt-1 text-sm text-gray-500">{product.color}</p>
        <p className="mt-1 text-sm font-medium text-gray-900">{product.price}</p>
      </Link>
    ))
  );
}

function Home() {
  const {
    data,
    isLoading,
  } = useSWR(
    'categories',
    (key) => {
      return client.collection(key).getList();
    },
  );

  return (
    <div className="bg-white">
      <header className="relative bg-white">
        <nav aria-label="Top" className="mx-auto max-w-7xl px-4 sm:px-6 lg:px-8">
          <div className="border-b border-gray-200">
            <div className="flex h-16 items-center">
              {/* Logo */}
              <div className="ml-4 flex lg:ml-0">
                <a href="#">
                  <span className="sr-only">Your Company</span>
                  <img
                    className="h-8 w-auto"
                    src="https://tailwindui.com/img/logos/mark.svg?color=indigo&shade=600"
                    alt=""
                  />
                </a>
              </div>

              {/* Flyout menus */}
              <Popover.Group className="hidden lg:ml-8 lg:block lg:self-stretch">
                <div className="flex h-full space-x-8">
                  {data?.items.map((page) => (
                    <a
                      key={page.name}
                      href={page.id}
                      className="flex items-center text-sm font-medium text-gray-700 hover:text-gray-800"
                    >
                      {page.name}
                    </a>
                  ))}
                </div>
              </Popover.Group>
            </div>
          </div>
        </nav>
      </header>

      <main className="mx-auto max-w-2xl px-4 pb-24 pt-16 sm:px-6 lg:max-w-7xl lg:px-8">

        {/* content */}
        {data?.items.map((category) => {
          return (
            <div key={category.id} className="bg-white">
              <div className="mx-auto max-w-2xl px-4 py-16 sm:px-6 sm:py-24 lg:max-w-7xl lg:px-8">
                <div className="md:flex md:items-center md:justify-between">
                  <h2 className="text-2xl font-bold tracking-tight text-gray-900">{category.name}</h2>
                  <a href="#" className="hidden text-sm font-medium text-indigo-600 hover:text-indigo-500 md:block">
                    Shop
                    <span aria-hidden="true"> &rarr;</span>
                  </a>
                </div>

                <div className="mt-6 grid grid-cols-2 gap-x-4 gap-y-10 sm:gap-x-6 md:grid-cols-4 md:gap-y-0 lg:gap-x-8">
                  <Category categoryId={category.id} />
                </div>

                <div className="mt-8 text-sm md:hidden">
                  <a href="#" className="font-medium text-indigo-600 hover:text-indigo-500">
                    Shop the collection
                    <span aria-hidden="true"> &rarr;</span>
                  </a>
                </div>
              </div>
            </div>
          );
        })}
        {/* end content */}

      </main>

      <footer aria-labelledby="footer-heading" className="bg-white">
        <div className="mx-auto max-w-7xl px-4 sm:px-6 lg:px-8">
          <div className="border-t border-gray-100 py-10 text-center">
            <p className="text-sm text-gray-500">&copy; 2021 Your Company, Inc. All rights reserved.</p>
          </div>
        </div>
      </footer>
    </div>
  )
}

const router = createBrowserRouter([
  {
    path: "/",
    element: <Home />,
  },
  {
    path: "/part/:id",
    element: <ProductPage />,
  },
]);

function App() {
  return (
    <RouterProvider router={router} />
  );
}

export default App;